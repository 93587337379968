@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Condensed";
  src: url("../public//fonts/BricolageGrotesqueCondensed-ExtraBold.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Bricolage Grotesque", sans-serif;
}
